/* eslint-disable @typescript-eslint/no-unused-vars */
import { breakpoints } from '../config'
import { IArtDirectionGenerated, IArtDirectionSourceTags, IBreakpointsKeys } from '../model'
import { ImgProps } from 'next/dist/shared/lib/get-img-props'

export const generateSourceTags = (
  artDirectionGenerated: IArtDirectionGenerated,
): IArtDirectionSourceTags => {
  if (!artDirectionGenerated) return []

  return Object.entries(artDirectionGenerated)
    .reverse()
    .map(([key, va], k) => {
      const breakpointKey = key as IBreakpointsKeys
      const artDirectionVal = (artDirectionGenerated[breakpointKey] || {}) as ImgProps & {
        alt: string
      }

      if (!artDirectionVal.srcSet) return

      const { width, height, alt, color, style, src, ...props } = artDirectionVal

      // @ts-ignore
      return <source key={key} media={`(max-width: ${breakpoints[breakpointKey]}px)`} {...props} />
    })
}
