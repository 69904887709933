import { INextLink } from '@/app/src/shared/model'
import { AnyIconName } from '../../Icon/lib'
import cn from 'classnames'

export type ISizes = 's' | 'm' | 'l' | 'xl'
export type IVariants =
  | 'main'
  | 'main-outline'
  | 'main-ghost'
  | 'main-ghost-hidden-padding'
  | 'secondary'
  | 'white-outline'
  | 'white-outline-ghost'
  | 'gray-outline'
type IHoverVariants = 'default' | 'purple-light'

type IGetButton = {
  size?: ISizes
  variant?: IVariants
  hoverVariant?: IHoverVariants
  disabled?: boolean
  fullWidth?: boolean
  squared?: boolean
  className?: string
}

type IGetButtonWrapper = {
  hasLeftNeighbour?: boolean
  hasRightNeighbour?: boolean
  hoverWithInRightNeighbour?: boolean
}

export type IButtonProps = {
  className?: string
  children: React.ReactNode
  size?: ISizes
  variant?: IVariants
  hoverVariant?: IHoverVariants
  href?: INextLink
  disabled?: boolean
  type?: 'button' | 'submit'
  iconNameLeft?: AnyIconName
  iconNameRight?: AnyIconName
  hasIconRight?: boolean
  hasIconLeft?: boolean
  hasRightNeighbour?: boolean
  hasLeftNeighbour?: boolean
  hoverWithInRightNeighbour?: boolean
  external?: boolean
  fullWidth?: boolean
  tag?: 'button' | 'div'
  squared?: boolean
  onClick?: () => void
}

export const buttonLib = {
  getButtonClasses: ({
    size,
    variant,
    hoverVariant,
    disabled,
    fullWidth,
    squared,
    className,
  }: IGetButton) => {
    return cn(
      'button',
      `button--size-${size}`,
      `button--variant-${variant}`,
      `button--hover-variant-${hoverVariant}`,
      {
        'button--disabled': disabled,
        'button--full-width': fullWidth,
        'button--is-squared': squared,
        [`${className}`]: !!className,
      },
    )
  },

  getButtonNeighbourWrapperClasses: ({
    hasLeftNeighbour,
    hasRightNeighbour,
    hoverWithInRightNeighbour,
  }: IGetButtonWrapper) => {
    return cn('button-neighbour-wrapper', {
      'button-neighbour-wrapper--left': hasLeftNeighbour,
      'button-neighbour-wrapper--right': hasRightNeighbour,
      'button-neighbour-wrapper--hover-with-in-right-neighbour': hoverWithInRightNeighbour,
    })
  },

  eventClick: (e: React.SyntheticEvent, disabled: boolean, onClick: () => void) => {
    if (disabled) {
      e.preventDefault()
      return
    }

    onClick()
  },
}
