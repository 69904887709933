import Link from 'next/link'
import { IconWithText } from '@/app/src/shared/ui/Button/IconWithText'
import { IButtonProps, buttonLib } from '@/app/src/shared/ui/Button/lib'

export const Tag = ({
  className,
  children,
  size = 'xl',
  variant = 'secondary',
  hoverVariant = 'default',
  href,
  iconNameRight = 'sprite/chevron-right',
  iconNameLeft = 'sprite/chevron-left',
  disabled = false,
  hasIconRight = false,
  hasIconLeft = false,
  external = false,
  fullWidth = false,
  onClick = () => {},
  type = 'button',
  tag = 'button',
  squared = false,
}: IButtonProps) => {
  const ElTag = tag
  const target = external ? '_blank' : '_self'
  const classes = buttonLib.getButtonClasses({
    size,
    variant,
    hoverVariant,
    disabled,
    fullWidth,
    squared,
    className,
  })

  const renderIconWithText = (
    <IconWithText
      hasIconRight={hasIconRight}
      hasIconLeft={hasIconLeft}
      iconNameLeft={iconNameLeft}
      iconNameRight={iconNameRight}
    >
      {children}
    </IconWithText>
  )

  return (
    <>
      {href ? (
        <Link className={classes} href={href} target={target}>
          {renderIconWithText}
        </Link>
      ) : (
        <ElTag
          onClick={(e: React.SyntheticEvent) => buttonLib.eventClick(e, disabled, onClick)}
          className={classes}
          type={type}
        >
          {renderIconWithText}
        </ElTag>
      )}
    </>
  )
}
