import { IArticlesDto, IArticlesRecentDto } from './dto'
import {
  IArticleCategories,
  IArticleCategory,
  IArticles,
  IArticlesRecent,
  IArticleTag,
  IArticleTags,
} from '../model'
import { mapCollection } from '@/app/src/shared/lib/mapCollection'
import { mapArticle, mapCategory, mapRecentArticle, mapTag } from './mapper'
import apiClient from '@/app/src/shared/api/base'
import { API_URL_NEW } from '@/app/src/app/config/env'
import { IArticlesQuery } from './query'
import { mapMeta } from '@/app/src/shared/api/mapper'

export const getRecentArticles = async (): Promise<IArticlesRecent> => {
  const result = await apiClient<IArticlesRecentDto>({
    url: `${API_URL_NEW}/recent-articles`,
    method: 'GET',
  })

  return {
    data: mapCollection(result?.data, mapRecentArticle),
  }
}

export const getArticles = async (query: IArticlesQuery): Promise<IArticles> => {
  const result = await apiClient<IArticlesDto>({
    url: `${API_URL_NEW}/articles`,
    method: 'GET',
    params: query,
  })

  return {
    data: mapCollection(result?.data, mapArticle),
    meta: result?.meta ? mapMeta(result.meta) : null,
  }
}

export const getCategories = async (): Promise<IArticleCategories> => {
  const result = await apiClient<IArticleCategories>({
    url: `${API_URL_NEW}/articles/categories`,
    method: 'GET',
  })

  return {
    categories: mapCollection(result.categories, mapCategory),
  }
}

export const getCategory = async (slug?: string): Promise<IArticleCategory | undefined> => {
  const result = await getCategories()

  return result?.categories.find((category) => category?.slug === slug)
}

export const getTags = async (): Promise<IArticleTags> => {
  const result = await apiClient<IArticleTags>({
    url: `${API_URL_NEW}/articles/tags`,
    method: 'GET',
  })

  return {
    tags: mapCollection(result.tags, mapTag),
  }
}

export const getTag = async (slug?: string): Promise<IArticleTag | undefined> => {
  const result = await getTags()

  return result?.tags.find((tag) => tag?.slug === slug)
}
