export interface SpritesMap {
  sprite:
    | 'add'
    | 'arrow-down'
    | 'arrow-left'
    | 'arrow-outside'
    | 'arrow-right'
    | 'arrow-up'
    | 'attechment'
    | 'attention'
    | 'automation'
    | 'back'
    | 'behance'
    | 'blockchain'
    | 'box'
    | 'brainstorm'
    | 'burger'
    | 'calendar'
    | 'caret-down'
    | 'caret-left'
    | 'caret-right'
    | 'caret-up'
    | 'case'
    | 'check'
    | 'chevron-down'
    | 'chevron-left'
    | 'chevron-right'
    | 'chevron-up'
    | 'clear'
    | 'clock'
    | 'coins'
    | 'copy'
    | 'cross-fancy'
    | 'cross'
    | 'crowdfunding'
    | 'current-order'
    | 'cursor'
    | 'delete'
    | 'destination'
    | 'done'
    | 'dots'
    | 'dribbble-arrow'
    | 'dribbble-text'
    | 'dribbble'
    | 'e-commerce'
    | 'edit'
    | 'event'
    | 'expand'
    | 'export'
    | 'facebook-case'
    | 'facebook'
    | 'favorite'
    | 'filter-mob-two'
    | 'fire'
    | 'flash'
    | 'flter-mob'
    | 'github'
    | 'globe'
    | 'history-orders'
    | 'honor-board'
    | 'how-we-work'
    | 'info'
    | 'instagram'
    | 'insurance'
    | 'keyboard'
    | 'laptop'
    | 'linkedin-case'
    | 'linkedin'
    | 'list'
    | 'location'
    | 'logout'
    | 'mail'
    | 'map'
    | 'mcn'
    | 'medium'
    | 'menu'
    | 'minus'
    | 'mobile-development'
    | 'mobile'
    | 'network'
    | 'notification'
    | 'office-1'
    | 'office-2'
    | 'online-shop'
    | 'order-tracking'
    | 'our-products'
    | 'our-team'
    | 'partner-with-us'
    | 'phone'
    | 'plus'
    | 'point-icon'
    | 'pos'
    | 'qality-testing'
    | 'question-two'
    | 'question'
    | 'quotes'
    | 'restaurant'
    | 'rules'
    | 'schedule'
    | 'search'
    | 'settings'
    | 'share'
    | 'shopping-bag'
    | 'smart'
    | 'sort-marked'
    | 'sort'
    | 'star'
    | 'stores'
    | 'suitcase'
    | 'table'
    | 'text-bubble'
    | 'tick-fancy'
    | 'timer'
    | 'travel-hospitality'
    | 'twitter-case'
    | 'twitter'
    | 'upload'
    | 'user-profile'
    | 'user'
    | 'ux-ui-design'
    | 'web-development'
    | 'youtube'
}
export const SPRITES_META: {
  [Key in keyof SpritesMap]: {
    filePath: string
    items: Record<
      SpritesMap[Key],
      {
        viewBox: string
        width: number
        height: number
      }
    >
  }
} = {
  sprite: {
    filePath: 'sprite.8c968655.svg',
    items: {
      add: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'arrow-down': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'arrow-left': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'arrow-outside': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'arrow-right': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'arrow-up': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      attechment: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      attention: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      automation: {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      back: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      behance: {
        viewBox: '0 0 22 22',
        width: 22,
        height: 22,
      },
      blockchain: {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      box: {
        viewBox: '0 0 16 17',
        width: 16,
        height: 17,
      },
      brainstorm: {
        viewBox: '0 0 16 18',
        width: 16,
        height: 18,
      },
      burger: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      calendar: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'caret-down': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'caret-left': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'caret-right': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'caret-up': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      case: {
        viewBox: '0 0 18 18',
        width: 18,
        height: 18,
      },
      check: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'chevron-down': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'chevron-left': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'chevron-right': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'chevron-up': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      clear: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      clock: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      coins: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      copy: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'cross-fancy': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      cross: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      crowdfunding: {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      'current-order': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      cursor: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      delete: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      destination: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      done: {
        viewBox: '0 0 10 10',
        width: 10,
        height: 10,
      },
      dots: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'dribbble-arrow': {
        viewBox: '0 0 23 23',
        width: 23,
        height: 23,
      },
      'dribbble-text': {
        viewBox: '0 0 88 25',
        width: 88,
        height: 25,
      },
      dribbble: {
        viewBox: '0 0 22 22',
        width: 22,
        height: 22,
      },
      'e-commerce': {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      edit: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      event: {
        viewBox: '0 0 16 17',
        width: 16,
        height: 17,
      },
      expand: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      export: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'facebook-case': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      facebook: {
        viewBox: '0 0 22 22',
        width: 22,
        height: 22,
      },
      favorite: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'filter-mob-two': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      fire: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      flash: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'flter-mob': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      github: {
        viewBox: '0 0 22 22',
        width: 22,
        height: 22,
      },
      globe: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'history-orders': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'honor-board': {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      'how-we-work': {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      info: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      instagram: {
        viewBox: '0 0 22 22',
        width: 22,
        height: 22,
      },
      insurance: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      keyboard: {
        viewBox: '0 0 18 12',
        width: 18,
        height: 12,
      },
      laptop: {
        viewBox: '0 0 20 21',
        width: 20,
        height: 21,
      },
      'linkedin-case': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      linkedin: {
        viewBox: '0 0 22 22',
        width: 22,
        height: 22,
      },
      list: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      location: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      logout: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      mail: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      map: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      mcn: {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      medium: {
        viewBox: '0 0 22 22',
        width: 22,
        height: 22,
      },
      menu: {
        viewBox: '0 0 22 20',
        width: 22,
        height: 20,
      },
      minus: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'mobile-development': {
        viewBox: '0 0 32 33',
        width: 32,
        height: 33,
      },
      mobile: {
        viewBox: '0 0 20 21',
        width: 20,
        height: 21,
      },
      network: {
        viewBox: '0 0 20 21',
        width: 20,
        height: 21,
      },
      notification: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'office-1': {
        viewBox: '0 0 43 44',
        width: 43,
        height: 44,
      },
      'office-2': {
        viewBox: '0 0 43 44',
        width: 43,
        height: 44,
      },
      'online-shop': {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      'order-tracking': {
        viewBox: '0 0 20 21',
        width: 20,
        height: 21,
      },
      'our-products': {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      'our-team': {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      'partner-with-us': {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      phone: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      plus: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'point-icon': {
        viewBox: '0 0 38 38',
        width: 38,
        height: 38,
      },
      pos: {
        viewBox: '0 0 20 21',
        width: 20,
        height: 21,
      },
      'qality-testing': {
        viewBox: '0 0 32 33',
        width: 32,
        height: 33,
      },
      'question-two': {
        viewBox: '0 0 18 18',
        width: 18,
        height: 18,
      },
      question: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      quotes: {
        viewBox: '0 0 35 27',
        width: 35,
        height: 27,
      },
      restaurant: {
        viewBox: '0 0 18 18',
        width: 18,
        height: 18,
      },
      rules: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      schedule: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      search: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      settings: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      share: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'shopping-bag': {
        viewBox: '0 0 20 20',
        width: 20,
        height: 20,
      },
      smart: {
        viewBox: '0 0 16 17',
        width: 16,
        height: 17,
      },
      'sort-marked': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      sort: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      star: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      stores: {
        viewBox: '0 0 18 18',
        width: 18,
        height: 18,
      },
      suitcase: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      table: {
        viewBox: '0 0 16 14',
        width: 16,
        height: 14,
      },
      'text-bubble': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'tick-fancy': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      timer: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'travel-hospitality': {
        viewBox: '0 0 36 37',
        width: 36,
        height: 37,
      },
      'twitter-case': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      twitter: {
        viewBox: '0 0 22 22',
        width: 22,
        height: 22,
      },
      upload: {
        viewBox: '0 0 15 16',
        width: 15,
        height: 16,
      },
      'user-profile': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      user: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      'ux-ui-design': {
        viewBox: '0 0 32 33',
        width: 32,
        height: 33,
      },
      'web-development': {
        viewBox: '0 0 32 33',
        width: 32,
        height: 33,
      },
      youtube: {
        viewBox: '0 0 22 22',
        width: 22,
        height: 22,
      },
    },
  },
}
