import cn from 'classnames'

import { IReview } from '../../model'
import { Icon } from '@/app/src/shared/ui/Icon'

import './Review.scss'

export type IProps = {
  className?: string
  hoverVariant?: 'default' | 'arrow-animate'
  review?: IReview
  size?: 'm' | 's'
}

const Review = ({ review, hoverVariant = 'default', size = 'm' }: IProps) => {
  return (
    <a
      className={cn('review', `review--hover-variant-${hoverVariant}`, `review--size-${size}`)}
      href={review?.link ?? ''}
      target="_blank"
    >
      <div className="review__top">
        <div className="review__top-left">
          <div className="review__stars">
            {review?.rating
              ?.repeat(+review.rating)
              .split('')
              .map((_, key) => (
                <div key={key} className="review__star-icon">
                  <Icon name="sprite/star" />
                </div>
              ))}
          </div>
        </div>
        <div className="review__top-right">
          <div className="review__arrow-icon">
            <Icon width="24" height="24" name="sprite/arrow-outside" />
          </div>
        </div>
      </div>

      <blockquote className="review__body">
        <p className="review__text">{review?.text}</p>
        <footer className="review__who">
          <div className="review__who-name">{review?.author}</div>
          <cite className="review__who-position">{review?.post_author}</cite>
        </footer>
      </blockquote>
    </a>
  )
}

export default Review
