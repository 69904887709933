import { format } from 'date-fns'
import { formatISO } from 'date-fns'

const getDate = (val: string) => {
  const [day, month, year] = val ? val.split('.') : '27.04.1994'.split('.')

  return new Date(+year, +month - 1, +day)
}

export const formatDate = (val: string) => {
  return format(getDate(val), 'MMM d, yyyy')
}

export const formatISODate = (val: string) => {
  return formatISO(getDate(val))
}
