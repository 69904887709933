import(/* webpackMode: "eager" */ "/app/app/(headerWhite)/_images/beerpoint@2x.jpg");
;
import(/* webpackMode: "eager" */ "/app/app/(headerWhite)/_images/burger@2x-min.png");
;
import(/* webpackMode: "eager" */ "/app/app/(headerWhite)/_images/bushe@2x.jpg");
;
import(/* webpackMode: "eager" */ "/app/app/(headerWhite)/_images/cart@2x-min.png");
;
import(/* webpackMode: "eager" */ "/app/app/(headerWhite)/_images/fh@2x.jpg");
;
import(/* webpackMode: "eager" */ "/app/app/(headerWhite)/_images/templates@2x-min.png");
;
import(/* webpackMode: "eager" */ "/app/app/(headerWhite)/_images/yapoki@2x.jpg");
;
import(/* webpackMode: "eager" */ "/app/app/(headerWhite)/_ui/Expertise/Expertise.scss");
;
import(/* webpackMode: "eager", webpackExports: ["ExperticeItem"] */ "/app/app/(headerWhite)/_ui/Expertise/ExpertiseItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ButtonWorkWithUs"] */ "/app/app/(headerWhite)/_ui/Intro/Button.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/(headerWhite)/_ui/Intro/Intro.scss");
;
import(/* webpackMode: "eager" */ "/app/app/(headerWhite)/_ui/Projects/Projects.scss");
;
import(/* webpackMode: "eager" */ "/app/app/(headerWhite)/_ui/Statistics/Statistics.scss");
;
import(/* webpackMode: "eager" */ "/app/app/(headerWhite)/page.scss");
;
import(/* webpackMode: "eager" */ "/app/app/src/entities/technology/images/design-rush.png");
;
import(/* webpackMode: "eager" */ "/app/app/src/entities/technology/images/docker-compose.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/src/shared/ui/Button/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NextImageClient"] */ "/app/app/src/shared/ui/NextImage/NextImage.client.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/src/shared/ui/Text/Text.scss");
;
import(/* webpackMode: "eager" */ "/app/app/src/shared/ui/Title/Title.scss");
;
import(/* webpackMode: "eager" */ "/app/app/src/shared/ui/NextImage/NextImage.scss");
;
import(/* webpackMode: "eager" */ "/app/app/src/entities/technology/ui/Technology/Technology.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/src/widgets/Articles/ui/ArticlesCarousel/ArticlesCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/src/widgets/FeedbackForm/ui/FeedbackFormFields/FeedbackFormFields.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/src/widgets/GetInTouch/ui/GetInTouch/GetInTouch.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/src/widgets/Reviews/ui/Awards/Awards.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/src/widgets/Reviews/ui/Carousel/Carousel.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/src/widgets/Services/ui/Services/Services.scss");
;
import(/* webpackMode: "eager" */ "/app/app/src/widgets/Reviews/ui/Reviews/Reviews.scss");
;
import(/* webpackMode: "eager" */ "/app/app/src/widgets/Articles/ui/Articles.scss");
;
import(/* webpackMode: "eager" */ "/app/app/src/widgets/FeedbackForm/ui/FeedbackForm/FeedbackForm.scss");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
