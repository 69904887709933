'use client'

import cn from 'classnames'
import { Form } from '@/app/src/entities/application/ui/Form'
import { SubmitButton } from '@/app/src/features/send-application/ui/SubmitButton'
import { useFeedbackForm } from '@/app/src/features/send-application/hooks'
import { Title } from '@/app/src/shared/ui/Title'
import { Text } from '@/app/src/shared/ui/Text'
import { Button } from '@/app/src/shared/ui/Button'
import { Swiper, SwiperSlide } from 'swiper/react'

import './FeedbackFormFields.scss'
import { isDevEnv } from '@/app/src/app/config/env'

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export type IFeedbackFormFields = {}

// В дев режиме можно мышкой перетянуть слайд на состояние успешной отправки формы
const SWIPER_NO_SWIPING = !isDevEnv

// eslint-disable-next-line no-empty-pattern
const FeedbackFormFields = ({}: IFeedbackFormFields) => {
  const { handleBackClick, formAction, swiperRef, formRef, errors } = useFeedbackForm()

  return (
    <section className="feedback-form-fields">
      <Swiper
        spaceBetween={100}
        onSwiper={(swiper) => {
          swiperRef.current = swiper
        }}
      >
        <SwiperSlide className={cn({ 'swiper-no-swiping': SWIPER_NO_SWIPING })}>
          <div className="feedback-form-fields__fields">
            <Form onSubmit={formAction} errors={errors} ref={formRef}>
              <SubmitButton />
            </Form>
          </div>
        </SwiperSlide>

        <SwiperSlide className={cn({ 'swiper-no-swiping': SWIPER_NO_SWIPING })}>
          <div className="feedback-form-fields__congratulation">
            <div className="feedback-form-fields__congratulation-title">
              <Title size="5xl" color="white" tag="h2" className="">
                Thanks for getting in touch with us! 🤝
              </Title>
            </div>

            <div className="feedback-form-fields__congratulation-description">
              <Text weight="m" size="m" color="white">
                We will contact you within 2 business days. If you have any questions, please email
                us at success@dev.family
              </Text>
            </div>

            <div className="feedback-form-fields__congratulation-controls">
              <div className="feedback-form-fields__congratulation-control">
                <Button href="/project-evaluation#main" variant="white-outline">
                  Get Project Estimation
                </Button>
              </div>

              <div className="feedback-form-fields__congratulation-control">
                <Button onClick={handleBackClick} variant="white-outline-ghost">
                  Send another brief
                </Button>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  )
}

export default FeedbackFormFields
